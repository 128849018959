import React, { useState, useCallback } from "react";
import { useRentalDetailsContext } from "processes/Rentals/RentalDetails/RentalDetailsContext";
import { getTagTypeFromStatus } from "processes/Rentals/utils";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import { DriverVerifyModalCheck } from "components/Verification/DriverVerifyModal/DriverVerifyModalCheck";
import { ContentSectionElementText } from "components/ContentSection/elements/Text";
import { MatchMedia, MQ_BP, useMatchMedia } from "components/MatchMedia";
import Typography from "../../../../../components/Typography";
import Button from "../../../../../components/Button";
import RentalDetailsStatusBar from "../RentalDetailsStatusBar";
import RentalDetailsCancelationStatusContent from "../RentalDetailsCancelationStatusContent";
import Tag from "../../../../../components/Tag";
import cn from 'classnames';
import { getErrorsAndSuccess } from "utils/extractErrors";
import { handleRentalsSegment, checkCancelationStatus } from "processes/Rentals/utils";
import { useNeedDriverVerification } from "components/Verification/DriverVerifyModal/hooks/useNeedDriverVerification";
import { useUrlParams } from "components/Verification/DriverVerifyModal/hooks/useUrlParams";
import { DRIVER_AUTH_PASSED } from "components/Verification/DriverVerifyModal/constants";
import { useQuery, useMutation } from "@apollo/client";
import { medallionDataQuery } from "components/Verification/DriverVerifyModal/queries/medallionDataQuery";
import classes from './RentalDetailsStatus.module.css';
import { SpinnerBack } from "components/Spinner/Spinner";
import { generateMedallionToken } from "components/Verification/DriverVerifyModal/mutations/generateMedallionToken";
import { replace_url } from "components/Verification/DriverVerifyModal/constants";

const getAlertStatus = (type = null) => {
  switch (type) {
  case (null):
    return '';
  case 0:
    return 'info';
  case 1:
    return 'warning';
  case 2:
    return 'error';
  case 3:
    return 'success';
  default:
    return 'default';
  }
};

const medallionDataEAS = getErrorsAndSuccess("medallion_data");
const generateMedallionTokenMutationEAS = getErrorsAndSuccess("generate_medallion_token");

const RentalDetailsStatus = ({ handleScrollToAction }) => {
  const {
    rental,
    rental: {
      progress_bar,
      info_button: messageButton,
      info_title: statusMessage,
      info_text: message,
      additional_info: additionalInfo,
      status,
      action_button,
      alert
    },
    executeAction,
    refetch
  } = useRentalDetailsContext();

  const {
    isNeedDriverVerify,
    refetchDriverVerificationPending
  } = useNeedDriverVerification();

  const { getUrlWithAddedParam } = useUrlParams();

  const [error, setError] = useState("");

  const {
    loading: medallionDataQueryLoading,
    data: medallionDataQueryData,
    refetch: refetchMedallionDataQueryData
  } = useQuery(medallionDataQuery, {
    fetchPolicy: "no-cache"
  });

  const [generateMedallionTokenAction, { loading: generateMedallionTokenLoading }] = useMutation(generateMedallionToken);

  const alertNotification = {
    alertTitle: alert?.title,
    alertText: alert?.text,
    // alertTextType: alert?.text_type,
    alertType: alert?.type,
    alertButtons: alert?.buttons
  };
  const rentalId = rental?.id;

  const alertStatus = getAlertStatus(alertNotification.alertType);
  const isCancelationStatus = checkCancelationStatus(rental);
  const isMinLarge = useMatchMedia({ minWidth: MQ_BP.large });
  const messageType = "simple";
  const [isShowDriverVerifyModal, setShowDriverVerifyModal] = useState(false);

  const handleDigisureComplete = useCallback(() => {
    setShowDriverVerifyModal(false);
    refetch();
    refetchDriverVerificationPending();
  }, [refetch, refetchDriverVerificationPending]);

  const onShowDriverVerifyModal = useCallback(async() => {
    const response = await refetchMedallionDataQueryData();
    const { date_of_birth, first_name, last_name } = medallionDataEAS.getQueryData(response);

    if (date_of_birth && first_name && last_name) {
      const response = await generateMedallionTokenAction({
        variables: {
          date_of_birth,
          first_name,
          last_name,
          redirect_url: `${window.location.origin}/rentals/details?id=${rentalId}`
        }
      });
      if (generateMedallionTokenMutationEAS.isSuccess(response)) {
        const token = response.data.generate_medallion_token.token;
        window.location.replace(`${replace_url}${token}`);
      } else if(generateMedallionTokenMutationEAS.isErrors(response)) {
        setError(generateMedallionTokenMutationEAS.errorMessage(response));
      }
    } else {
      setShowDriverVerifyModal(true);
    }
  }, [generateMedallionTokenAction, refetchMedallionDataQueryData, rentalId]);

  const handleActionButtonClick = useCallback(() => {
    executeAction(action_button?.key);
    if (action_button?.key === "cancel") {
      handleRentalsSegment("Cancel Request");
    }
  }, [action_button?.key, executeAction]);

  const alertBnts = alertNotification?.alertButtons?.map((btn => {
    let onClick = null;
    let href = null;
    if (btn.id === 'driver_verification') {
      onClick = () => {
        handleRentalsSegment("Get Verified");
        onShowDriverVerifyModal();
      };
    }
    if (btn.link) {
      href = btn.link;
    }
    return (
      <div className="t-8">
        <Button
          size="medium"
          onClick={onClick}
          href={href}
          {...btn}
        />
      </div>
    );
  }));

  return (
    <>
      {(medallionDataQueryLoading || generateMedallionTokenLoading) && <SpinnerBack />}
      <section
        className={cn(
          "rental-details-status",
          isMinLarge && isCancelationStatus && "mt-28",
          isCancelationStatus && classes.rentalDetailsStatusContainer
        )}
      >
        {!isCancelationStatus && (
          <>
            <div className="rental-details-status__title">
              <Typography
                variant="subtitle"
                size="m"
                component="h2"
                className="rental-details-status__text"
              >
                {statusMessage}
              </Typography>

              {action_button && (
                <div className="t-20 b-20 rental-details-status__btn-wrap">
                  <Button
                    secondary
                    onClick={handleActionButtonClick}
                  >
                    {action_button.value}
                  </Button>
                </div>
              )}
            </div>

            <div className="rental-details-status__title-mobile">
              <Typography
                variant="subtitle"
                size="s"
                component="h2"
                className="rental-details-status__text"
              >
                <span className="fw-500">Status</span>
              </Typography>
              <p className="t-0 b-0">
                <Tag
                  size="small"
                  text={status.name}
                  type={getTagTypeFromStatus(status)}
                  className="rental-details-status__tag"
                />
              </p>
            </div>

            <div className="t-m-20 b-m-20 b-l-0">
              {progress_bar && (
                <RentalDetailsStatusBar
                  payload={progress_bar}
                  statusMessage={statusMessage}
                  message={message}
                  messageType={messageType}
                />
              )}
            </div>

            <div className={classes.messageBlock}>
              {message &&
                <div className={classes.message}>
                  <ContentSectionElementText
                    variant="subtitle"
                    size="s"
                    className="rental-details-status__message rental-details-status__text"
                    content={{ type: messageType, value: message }}
                  />
                </div>
              }

              {messageButton &&
                <div className={classes.action}>
                  <Button ghost onClick={handleScrollToAction} className={classes.button}>
                    {messageButton}
                  </Button>
                </div>
              }
            </div>

            <div className="t-16 t-m-20" />
          </>
        )}

        {isCancelationStatus && (
          <div className="ml-neg-16 mr-neg-16 mt-8 ml-neg-m-0 mr-neg-m-0 mt-m-0">
            <div className="devider-solid devider-solid--light mb-8 dn-m" />
            <div className="rental-details-status__title-mobile">
              <Typography
                variant="subtitle"
                size="s"
                component="h2"
                className="rental-details-status__text"
              >
                <span className="fw-500">Status</span>
              </Typography>
              <p className="t-0 b-0">
                <Tag
                  size="small"
                  text={status.name}
                  type={getTagTypeFromStatus(status)}
                  className="rental-details-status__tag"
                />
              </p>
            </div>
            <RentalDetailsCancelationStatusContent
              title={statusMessage}
              reason={message}
              details={additionalInfo}
            />
          </div>
        )}

        {Boolean(alert) && (
          <article
            className="rental-details-status__alert"
            aria-label={alertNotification.alertTitle}
          >
            <NotificationPopover
              show={true}
              variant="static"
              status={alertStatus}
              title={alertNotification.alertTitle}
              text={alertNotification.alertText}
            >
              {alertBnts}
            </NotificationPopover>
          </article>
        )}

        {error?.length > 0 && (
          <NotificationPopover
            show
            status="error"
            text={error}
            onClose={() => {
              setError("");
            }}
          />
        )}

        <DriverVerifyModalCheck
          show={isShowDriverVerifyModal}
          isNeedVerify={isNeedDriverVerify}
          onClose={handleDigisureComplete}
          redirectURL={`${window.location.origin}/rentals/details?id=${rentalId}`}
        />
      </section>
    </>
  );
};

export default RentalDetailsStatus;
