import React from "react";
import Typography from "components/Typography";
import Button from "components/Button";
import classes from "./ProfileInfo.module.css";
import { ProfileMainInfo } from "../ProfileMainInfo/ProfileMainInfo";

export const ProfileInfo = (props) => {
  const {
    aboutTitle = "",
    aboutText = "",
    profileInfoButtonLabel = "",
    profileMainInfoData,
    avatarData
  } = props;
  return (
    <div className={`${classes.container} t-8 l-m-24 r-m-24 b-12 b-m-32 t-xl-12 r-xl-32 l-xl-32`}>
      <div className={classes.content}>

        <div className={`${classes.mainInfo}`}>
          <ProfileMainInfo data={profileMainInfoData} avatarData={avatarData} />
        </div>

        <div className={`${classes.about}`}>
          {aboutTitle.length !== 0 && (
            <>
              <div className="devider-solid mt-16 mb-16 mb-m-8 mt-xl-12" />
              <div className="">
                <Typography variant="subtitle" size="m" weight="bold" className={classes.aboutTitle}>
                  {aboutTitle}
                </Typography>
              </div>
            </>
          )}
          {aboutText.length !== 0 && (
            <>
              <div className={`${classes.aboutText} mt-l-4`}>
                <Typography variant="body" size="l" className={classes.aboutText}>
                  {aboutText}
                </Typography>
              </div>

            </>
          )}
          {profileInfoButtonLabel.length !== 0 && (
            <div className="dn db-m mt-m-20">
              <Button
                label={profileInfoButtonLabel}
                onClick={function noRefCheck(){}}
                size="medium"
                className={classes.editProfileButton}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// PublicProfile.propTypes = {
//   header: PropTypes.string.isRequired,
//   isMobileNavMode: PropTypes.bool.isRequired,
//   setIsMobileNavMode: PropTypes.func.isRequired
// };

// PublicProfile.defaultProps = {
//   header: "My Account",
//   isMobileNavMode: true
// };
