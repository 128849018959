import React from "react";
import classnames from "classnames";
import { useLocation } from "react-router-dom";
import Button from "components/Button";
import Typography from "../../../Typography";
import { mailIcon, headphonesIcon }  from "components/Icon";
import { ProTips } from "../../../ProTips";
import { Button as ButtonContent } from "components/ContentSection/elements";
import { getActiveSection } from "../../../../utils/getActiveSection";
import style from "./style.module.css";
import ReferralAsideInfo from "../ReferralAsideInfo/ReferralAsideInfo";

export const AsideInfo = ({ className, showReferralNotification }) => {
  const classNames = classnames(style["aside-info"], className);
  const { pathname } = useLocation();

  return (
    <aside className={classNames}>
      <ProTips section={getActiveSection(pathname)} style={style} />

      {showReferralNotification && <ReferralAsideInfo />}

      <div
        className={`${style["aside-info__item"]} ${style["aside-info__item-fill"]}`}
      >
        <div className={style["aside-info__block"]}>
          <Typography
            className={style["aside-info__title"]}
            component="h6"
            variant="subtitle"
            size="m"
          >
            <strong>Need Support?</strong>
          </Typography>
        </div>
        <div className={style["aside-info__block"]}>
          <div className={style["aside-info__description"]}>
            <Typography component="p" variant="body">
              Have questions or need assistance? We’re here to help.
            </Typography>
          </div>
          <div className={style["aside-info__contacts"]}>
            {false && (
              <Button
                className={classnames(style.headphonesButton, 'underline')}
                icon={headphonesIcon}
                iconPosition="before"
                ghost
                href="tel:1-888-626-7576"
                label={(
                  <>
                    <span className="dn db-xl">1-888-626-7576</span>
                    <span className="dn-xl">Call us</span>
                  </>
                )}
              />
            )}
            {true && (
              <ButtonContent
                id="rv_rentals_support_email"
                section="linksContent"
                label={(
                  <>
                    <span className="dn db-xl">support@rvrentals.com</span>
                    <span className="dn-xl">Email us</span>
                  </>
                )}
                ghost
                icon={mailIcon}
                iconPosition="before"
                prefixLink="mailto:"
                className={classnames('', style.rv_rentals_support_email, 'underline')}
              />
            )}
          </div>
        </div>
      </div>
    </aside>
  );
};
