import { gql } from "@apollo/client";

export const medallionDataQuery = gql`  
  query {
    medallion_data {
      date_of_birth
      first_name
      last_name
    }
  }
`;
