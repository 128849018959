import React, { useCallback } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment";
import Typography from "../../Typography";
import Tag from "../../Tag";
import { tagTypeByStatus } from "../utils";
import { ThreadInfoType, ThreadType } from "../types";
import classes from "./ThreadsListItem.module.css";

const today = moment();
const yesterday = moment().subtract(1, "day");

const ThreadsListItem = (props) => {
  const { thread, isOnline, info, unreadCount, onClick } = props;
  const dateCreated = thread.lastMessage?.dateCreated || thread.dateCreated;
  const dateTimeMoment = moment(dateCreated);
  const isToday = dateTimeMoment.isSame(today, "day");
  const isThisYear = dateTimeMoment.isSame(today, "year");
  const isYesterday = !isToday && dateTimeMoment.isSame(yesterday, "day");

  const _onClick = useCallback(() => {
    onClick(thread);
  }, [onClick, thread]);

  if (!info?.title) {
    return null;
  }

  return (
    <div
      className={classnames(classes.root, "fsc t-16 b-16 r-24 l-24")}
      onClick={_onClick}
    >
      <div className={classnames(classes.avatar, "df mr-12")}>
        <img src={info.avatar} alt="" />
        {isOnline && (
          <div className={classes.onlineIndicator} />
        )}
      </div>
      <div className={classes.startInfo}>
        <Typography
          className={classes.name}
          variant="body"
          size="l"
          weight="bold"
        >
          {info.title}
        </Typography>
        {false && ( // TODO: Thread Custom Status
          <Tag
            className={classes.status}
            size="small"
            text={thread.status.text}
            type={tagTypeByStatus[thread.status.id]}
          />
        )}
      </div>
      <div className={classes.endInfo}>
        {dateCreated && (
          <Typography
            className={classes.dateTime}
            variant="body"
            size="s"
          >
            {isYesterday ? "Yesterday" : dateTimeMoment.format(
              isToday ? "hh:mm a" : isThisYear ? "MMM D" : "MMM D, YYYY"
            )}
          </Typography>
        )}
        <div
          className={classnames(
            classes.unread,
            { [classes.unreadVisible]: unreadCount > 0 }
          )}
        >
          {unreadCount}
        </div>
      </div>
    </div>
  );
};

ThreadsListItem.propTypes = {
  thread: ThreadType.isRequired,
  isOnline: PropTypes.bool.isRequired,
  info: ThreadInfoType,
  unreadCount: PropTypes.number,
  onClick: PropTypes.func
};

export default ThreadsListItem;
