/*
 * Chat API Connection
 */

import { useCallback, useEffect, useRef, useState } from "react";
import sum from "lodash/sum";
import has from "lodash/has";
import { Client as ConversationsClient } from "@twilio/conversations";
import { useMutation, useQuery } from "@apollo/client";
import { tokenQuery } from "./queries/tokenQuery";
import { path } from "ramda";
import { chatConversationMutation } from "../../mutations";
import { dispatchChatOpenEvent } from "./utils";
import { getParticipantsFromThread } from "./utils/getParticipantsFromThread";

export const useApiClient = () => {
  const [apiClient, setApiClient] = useState();
  const [initError, setInitError] = useState(null);

  const initClient = useCallback(async (token) => {
    try {
      const client = await ConversationsClient.create(token);
      setApiClient(client);
    } catch (e) {
      console.error(e);
      setInitError(e);
    }
  }, []);

  useQuery(tokenQuery, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const token = data?.chat_token?.access_token;
      if (token) {
        void initClient(token);
      } else {
        setInitError("Loading token error");
      }
      // TODO: Extract error from token query
    }
  });

  return { apiClient, initError };
};

export const useConnectionState = ({ apiClient }) => {
  const [state, setState] = useState();

  useEffect(() => {
    if (apiClient) {
      apiClient.on("connectionStateChanged", setState);
      return () => apiClient.off("connectionStateChanged", setState);
    }
  }, [apiClient]);

  return { state, isLoaded: state === "connected" };
};

async function extractPaginatedConversations(paginator, items) {
  paginator.items.forEach(i => {
    items.push(i);
  });
  if (paginator.hasNextPage) {
    const nextPaginator = await paginator.nextPage();
    await extractPaginatedConversations(nextPaginator, items);
  }
}

async function getConversations({ apiClient }) {
  const paginator = await apiClient.getSubscribedConversations();
  if (paginator.items) {
    const items = [];
    await extractPaginatedConversations(paginator, items);
    return items;
  }
  return [];
}

const MESSAGES_PER_PAGE = 20;

export const useMessages = ({ thread }) => {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const loadingMoreRef = useRef(false); // Using ref also for instant access
  const prevThread = useRef();

  const addMessage = useCallback((message) => {
    setList(p => [...p, message]);
  }, []);

  useEffect(() => {
    if (prevThread.current) {
      prevThread.current.off("messageAdded", addMessage);
    }
    prevThread.current = thread;

    if (!thread) {
      return;
    }

    setLoading(true);
    thread.getMessages(MESSAGES_PER_PAGE)
      .then((paginator) => {
        const items = paginator.items || [];
        setList(items);
        setHasMore(paginator.hasPrevPage);
        setLoading(false);
        void thread.setAllMessagesRead();
      })
      .catch(err => {
        console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
        // TODO: Show error
        setLoading(false);
      });

    thread.on("messageAdded", addMessage);

    return () => thread.off("messageAdded", addMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thread]);

  const loadMore = useCallback(() => {
    if (list?.length > 0 && hasMore && !loadingMoreRef.current) {
      loadingMoreRef.current = true;
      setLoadingMore(true);
      thread.getMessages(MESSAGES_PER_PAGE, list[0].state.index - 1)
        .then((paginator) => {
          setList(p => [...(paginator.items || []), ...p]);
          setHasMore(paginator.hasPrevPage);
          loadingMoreRef.current = false;
          setLoadingMore(false);
        })
        .catch(err => {
          console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
          // TODO: Show error
          loadingMoreRef.current = false;
          setLoadingMore(false);
        });
    }
  }, [list, hasMore, thread]);

  const sendMessage = useCallback(async (message) => {
    if (thread && message) {
      thread.sendMessage(message.trim());
    }
  }, [thread]);

  return { loading, list, loadMore, hasMore, loadingMore, sendMessage };
};

export const useTypingState = ({ thread }) => {
  const [typingMember, setTypingMember] = useState(null);
  const prevThread = useRef();

  const typingStarted = useCallback((member) => {
    setTypingMember(member);
  }, []);

  const typingEnded = useCallback((member) => {
    setTypingMember(null);
  }, []);

  useEffect(() => {
    if (prevThread.current) {
      prevThread.current.off("typingStarted", typingStarted);
      prevThread.current.off("typingEnded", typingEnded);
    }
    prevThread.current = thread;

    if (thread) {
      thread.on("typingStarted", typingStarted);
      thread.on("typingEnded", typingEnded);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thread]);

  const setSelfTyping = useCallback(() => {
    if (thread) {
      thread.typing();
    }
  }, [thread]);

  return { typingMember, setSelfTyping };
};

export const useUnreadMessagesState = ({ threads, activeThread }) => {
  const [counts, setCounts] = useState({});
  const activeThreadRef = useRef();

  activeThreadRef.current = activeThread;

  const setThreadCount = useCallback((thread, getCount) => {
    const id = thread.sid;
    setCounts(p => ({
      ...p,
      [id]: getCount(p[id] || 0) || 0
    }));
  }, []);

  const loadCurrentState = useCallback(async (_threads) => {
    const result = {};
    for (const thread of _threads) {
      if (!has(counts, thread.sid)) {
        try {
          result[thread.sid] = await thread?.getUnreadMessagesCount?.();
        } catch (e) {
          console.error(e);
        }
      }
    }
    setCounts(p => {
      const newValue = {};
      Object.keys(result).forEach(id => (
        newValue[id] = (p[id] || 0) + (result[id] || 0)
      ));
      return newValue;
    });
  }, [counts]);

  const onMessageAdded = useCallback((value) => {
    const thread = value.conversation;
    if (thread === activeThreadRef.current) {
      // Don't increase count for currently active thread
      // And read new message automatically
      void thread.setAllMessagesRead();
    } else {
      setThreadCount(thread, (p) => p + 1);
    }
    // This callback mustn't be changed, as it's used for Twilio events
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getThreadUnreadCount = useCallback((thread) => {
    return (thread && counts[thread.sid]) || 0;
  }, [counts]);

  useEffect(() => {
    threads?.forEach(thread => {
      thread.off("messageAdded", onMessageAdded);
      thread.on("messageAdded", onMessageAdded);
      return () => thread.off("messageAdded", onMessageAdded);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threads]);

  useEffect(() => {
    if (activeThread) {
      setThreadCount(activeThread, (p) => 0);
    }
  }, [activeThread, setThreadCount]);

  useEffect(() => {
    void loadCurrentState(threads);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threads]);

  const totalUnread = sum(Object.values(counts));
  const hasUnreadMessages = totalUnread > 0;

  return { hasUnreadMessages, getThreadUnreadCount, totalUnread };
};

export const useReachabilityState = ({ threads, apiClient }) => {
  const [onlines, setOnlines] = useState({});

  const processThreads = useCallback(async (currentThreads) => {
    // Getting all new threads
    const newThreads = currentThreads?.filter(i => !has(onlines, i.sid));

    const initialOnlines = {};
    for (const newThread of newThreads) {
      const participants = await getParticipantsFromThread({
        thread: newThread,
        apiClient
      });
      for (const participant of participants) {
        // TODO: Won't work for multi-user chats - online status will be set from the last user everytime
        const user = await participant.getUser();
        if (user) {
          const id = newThread.sid;
          // Getting initial status
          initialOnlines[id] = user.isOnline;
          // Setting up a hook on updates
          user.on("updated", (event) => {
            const { user, updateReasons } = event;
            updateReasons.forEach(function (reason) {
              if (reason === "reachabilityOnline") {
                setOnlines(p => ({
                  ...p,
                  [id]: user.isOnline
                }));
              }
            });
          });
        }
      }
    }
    setOnlines(initialOnlines);
    // Mustn't include onlines, as this function changes it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiClient]);

  useEffect(() => {
    if (apiClient) {
      if (!apiClient.reachabilityEnabled) {
        return;
      }

      if (threads?.length > 0) {
        void processThreads(threads);
      }
    }
  }, [apiClient, threads, processThreads]);

  const isThreadOnline = useCallback((thread) => {
    return thread && onlines[thread.sid] === true;
  }, [onlines]);

  return { isThreadOnline };
};

const startChatMutationName = "chat_create_conversation";
const extractChatId = path([startChatMutationName, "conversation", "id"]);

export const useStartChatAction = (userId) => {
  const [startChat, startChatResult] = useMutation(chatConversationMutation, {
    variables: {
      user_id: userId
    },
    onCompleted: (data) => {
      const chatId = extractChatId(data);
      if (chatId) {
        dispatchChatOpenEvent(chatId);
      }
    }
  });

  return { startChat, startChatLoading: startChatResult.loading };
};
