import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { sendIcon } from "../../Icon";
import Header from "./Header";
import Footer from "./Footer";
import MessagesList from "./MessagesList";
import { ThreadType } from "../types";
import { useMessages, useTypingState } from "../api";
import classes from "./ThreadView.module.css";

const ThreadView = (props) => {
  const { apiClient, activeThread, onBack, onClose, getThreadInfo } = props;
  const list = useRef();
  const needToScroll = useRef(false);
  const [localThread, setLocalThread] = useState();
  const hasActiveThread = Boolean(activeThread);
  const isActiveThread = activeThread === localThread;

  const {
    loading,
    list: messages,
    sendMessage,
    loadMore,
    loadingMore,
    hasMore
  } = useMessages({ thread: activeThread });

  const {
    typingMember,
    setSelfTyping
  } = useTypingState({ thread: activeThread });

  useEffect(() => {
    if (activeThread) {
      setLocalThread(activeThread);
    }
  }, [activeThread]);

  const channelState = localThread?.channelState;
  const threadInfo = getThreadInfo(localThread);

  const onSendMessage = useCallback((value) => {
    needToScroll.current = true;
    void sendMessage(value);
  }, [sendMessage]);

  useEffect(() => {
    if (needToScroll.current) {
      needToScroll.current = false;
      list.current?.scrollToEnd?.();
    }
  }, [messages?.length]);

  return (
    <div
      className={classnames(
        classes.root,
        "df fdc",
        { [classes.open]: hasActiveThread }
      )}
    >
      {channelState && (
        <>
          <Header
            title={threadInfo?.title || channelState.friendlyName}
            onBack={onBack}
            onClose={onClose}
            rentals={localThread.rentals}
            hasActiveThread={hasActiveThread}
          />
          <MessagesList
            ref={list}
            apiClient={apiClient}
            loading={loading}
            loadingMore={loadingMore}
            messages={messages}
            thread={localThread}
            threadInfo={threadInfo}
            isActiveThread={isActiveThread}
            loadMore={loadMore}
            hasMore={hasMore}
            typingMember={typingMember}
          />
          <Footer
            thread={localThread}
            actionTitle="Submit"
            placeholder="Your message"
            onClickAction={onSendMessage}
            onInputChange={setSelfTyping}
            actionIcon={sendIcon}
            useTextarea
            clearValueOnClickAction
          />
        </>
      )}
    </div>
  );
};

ThreadView.propTypes = {
  apiClient: PropTypes.object.isRequired,
  activeThread: ThreadType,
  onBack: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  getThreadInfo: PropTypes.func.isRequired
};

export default memo(ThreadView);
