import React from "react";
import { rvInitialData } from "./queries/rvInitialData";
import { BookingContentProvider } from "./BookingContentProvider";
import { ContentProvider } from "components/ContentSection";
import Spinner from "components/Spinner/Spinner";
import { useQuery } from "@apollo/client";
import { useLoginRedirect } from "router/useLoginRedirect";
import { userQuery } from "./queries/userQuery";
import { featureFlagsListQuery } from "queries/featureFlagsListQuery";
import { checkIMGlobalIntegrationIsEnabled } from "utils/featureFlags/checkIMGlobalIntegrationIsEnabled";
import { membershipDetailsQuery } from "queries/membershipDetailsQuery";
import { isGoodSamMember } from "utils/isGoodSamMember";
import { ContentLoader } from "components/ContentSection";
import { linksContentQuery } from "queries/contentQuery";
import { contentPredicate } from "utils/contentPredicate";
import { CONTENT_QUERY_LINK } from "constants/contentQuery";
import classes from "./components/Booking.module.css";

function getRvId() {
  const href = window.location.href.replace(/(#.*|\?.*)?$/, "");
  const rvId = parseInt(href.split("-").reverse()[0], 10);

  return isNaN(rvId) ? null : rvId;
}

export const BookingContentProviderContainer = ({ rvId: propRvId }) => {
  const rvId = propRvId || getRvId();

  useLoginRedirect();

  const { data, loading, refetch: refetchRvInitialData } = useQuery(rvInitialData, {
    variables: {
      id: rvId,
      for_listing: false
    },
    fetchPolicy: "no-cache",
    skip: !rvId
  });

  const { loading: featureFlagsListLoading, data: featureFlagsListData } = useQuery(featureFlagsListQuery, {
    variables: {
      features: ["IMGlobal Integration Is Enabled"]
    },
    fetchPolicy: "no-cache",
    skip: !rvId
  });

  const { loading: membershipDetailsLoading, data: membershipDetailsData } = useQuery(membershipDetailsQuery, {
    fetchPolicy: "no-cache",
    skip: !rvId
  });

  if (!rvId) {
    console.log("RvID is not defined");
    return null;
  }

  if (
    loading || !data
    || !membershipDetailsData || membershipDetailsLoading
    || featureFlagsListLoading ||!featureFlagsListData
  ) {
    return <div className={classes.containerSpinnerPlaceholder}><Spinner position="absolute" /></div>;
  }
  const isIMGlobalIntegrationIsEnabled = checkIMGlobalIntegrationIsEnabled(featureFlagsListData);
  const isClientGoodSamMember = isGoodSamMember(membershipDetailsData?.membership_details);

  let rental_type = data.rv.allow_standard_rental ? 1 : 2;
  if (!data.rv.allow_standard_rental) rental_type = 2;

  const initialData = {
    analyticsData: {
      rvId,
      location: data.rv.location.short_address,
      asset_price: Math.round(data.rv.daily_rate?.cents_total / 100),
      asset_id: String(data.rv.id),
      asset_year: String(data.rv.year),
      asset_make: data.rv.make,
      asset_brand: data.rv.manufacturer,
      asset_model: data.rv.model,
      asset_weight: data.rv.weight,
      asset_slideouts: data.rv.slide_outs,
      asset_length: data.rv.length?.inches_total,
      asset_sleepnumber: data.rv.sleeps,
      asset_class: data.rv.class,
      owner_id: data.rv.owner.id,
      goodSamMember: isClientGoodSamMember
    },
    rvId,
    addons: data?.rv?.add_ons,
    dailyRate: data.rv.daily_rate.user_friendly,
    dailyRateDollarAsPart: data.rv.daily_rate.dollars_as_part,
    dailyRateCentsTotal: data.rv.daily_rate.cents_total,
    deliveryOption: data.rv.delivery_option,
    dorDeliveryOption: data.rv.dor_delivery_option,
    minimumDuration: data.rv.minimum_duration,
    initialLocation: data.rv.location,
    paidDelivery: data.rv.paid_delivery,
    dorPaidDelivery: data.rv.dor_paid_delivery,
    milesIncluded: data.rv.miles_included,
    generatorHoursIncluded: data.rv.generator_hours_included,
    limitedGeneratorHours: data.rv.limited_generator_hours,
    limitedDailyMileage: data.rv.limited_daily_mileage,
    generatorNotApplicable: data.rv.generator_not_applicable,
    isTrailedClass: data.rv.is_trailed_class,
    mileageOverageRate: data.rv.mileage_overage_rate.user_friendly,
    generatorOverageRate: data.rv.generator_overage_rate.user_friendly,
    availability_calendar: data.rv.availability_calendar,
    instantRentals: data.rv.instant_rentals,
    instantRentalDuration: data.rv.instant_rentals__days_before_the_rental,
    allowStandardRental: data.rv.allow_standard_rental,
    allowDeliveryOnlyRental: data.rv.allow_delivery_only_rental,
    specificDeliveryLocations: data.rv.specific_delivery_locations,
    dorSpecificDeliveryLocations: data.rv.dor_specific_delivery_locations,
    insurancePackage: undefined,
    isGoodSamMember: false,
    isClientGoodSamMember,
    goodsamMembershipPlanId: undefined,
    date: {
      departure: null,
      return: null
    },
    locationData: {
      1: {
        locationSelected: false,
        fullAddress: "",
        deliveryRadius: null,
        specificDeliveryLocation: null,
        radioState: 'location-1'
      },
      2: {
        locationSelected: false,
        fullAddress: "",
        deliveryRadius: null,
        specificDeliveryLocation: null,
        radioState: ''
      }
    },
    rental_type,
    specific_delivery_location_id: null,
    isLocationWarning: false,
    isShowDates: true,
    deliveryLocation: null,
    fullAddress: null,
    isIMGlobalIntegrationIsEnabled
  };

  return (
    <ContentProvider>
      <ContentLoader
        query={linksContentQuery}
        predicate={contentPredicate([CONTENT_QUERY_LINK])}
        render={() => (
          <BookingContentProvider initialData={initialData} refetchRvInitialData={refetchRvInitialData} />
        )}
      />
    </ContentProvider>
  );
};
