import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useForm, useWatch } from "react-hook-form";
import Typography from "components/Typography";
import { TextItem } from "components/TextItem";
import { FormElements } from "components/form/FormElementsBlock";
import cn from "classnames";
import classes from "./FormElementsBlock.module.css";

const FormElementsBlock = (props) => {
  const {
    form_header,
    form_footer,
    formParams,
    form_elements,
    form_tags
  } = props;

  const isFormHeaderValue = Boolean(form_header?.value);
  const isFormFooterValue = Boolean(form_footer?.value);

  return (
    <div>
      {isFormHeaderValue &&(
        <header className="mb-16 mb-m-20">
          <TextItem plainStyle={classes.formHeaderPlainStyle} data={form_header} />
        </header>
      )}

      <div className={classes.formElements}>
        <FormElements
          form_elements={form_elements}
          form_tags={form_tags}
          formParams={formParams}
        />
      </div>

      {isFormFooterValue && (
        <footer className="mt-20">
          <TextItem plainStyle={classes.formFooterPlainStyle} data={form_footer} />
        </footer>
      )}
    </div>
  );
};

FormElementsBlock.propTypes = {

};

export default FormElementsBlock;
