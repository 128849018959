import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import union from "lodash/union";
import { useApolloClient } from "@apollo/client";
import { chatOpponentQuery } from "../queries/chatOpponents";
import { getParticipantsFromThread } from "../utils/getParticipantsFromThread";

export const useThreads = ({ apiClient, chatOpponents }) => {
  const delayedActiveThreadSid = useRef("");
  const apolloClient = useApolloClient();
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const infoRef = useRef({}); // Using ref for accessing it at events' hooks
  const [, update] = useReducer(p => p + 1, 1);
  const [activeThread, setActiveThread] = useState(null);

  const generateThreadsInfo = useCallback(async (users, threadSid) => {
    const newInfo = {};
    for (const user of users) {
      newInfo[threadSid] = {
        id: user.id,
        avatar: user.avatar?.srcset?.[0]?.url,
        title: user.short_name
      };
    }
    return newInfo;
  }, []);

  const onLoad = useCallback(async () => {
    if (apiClient && apolloClient && chatOpponents) {
      const loadUserInfo = async (userId, threadId) => {
        const intUserId = Number(userId);
        if (intUserId && !infoRef.current[threadId]) { // Checking if it's not exists
          let opponent = chatOpponents.find(i => i.id === intUserId); // Checking if it was loaded before

          if (!opponent) {
            const { data: user } = await apolloClient.query({
              query: chatOpponentQuery,
              variables: { id: intUserId }
            });
            if (user?.user) {
              opponent = user?.user;
            }
          }

          if (opponent) {
            const newInfo = await generateThreadsInfo([opponent], threadId);
            infoRef.current = { ...infoRef.current, ...newInfo };
            update();
          }
        }
      };

      const onParticipantJoined = async (participant) => {
        await loadUserInfo(
          participant.state.identity,
          participant.conversation.sid
        );
      };

      const onJoin = async (thread) => {
        const participants = await getParticipantsFromThread({
          thread,
          apiClient
        });

        await Promise.all(participants.map(async (participant) => {
          await loadUserInfo(participant.state.identity, thread.sid);
        }));

        setList(p => union(p, [thread]));
        setLoading(false);
      };

      const onLeft = (conversation) => {
        setList(p => ([...p.filter((it) => it !== conversation)]));
      };

      apiClient.on("conversationAdded", onJoin);
      apiClient.on("participantJoined", onParticipantJoined); // Need to track this hook also (sometimes participants are added after joining the thread)
      apiClient.on("conversationLeft", onLeft);

      setLoading(false);

      return () => {
        apiClient.off("conversationAdded", onJoin);
        apiClient.off("participantJoined", onParticipantJoined);
        apiClient.off("conversationLeft", onLeft);
      };
    }
  }, [apiClient, apolloClient, chatOpponents, generateThreadsInfo]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const selectThread = useCallback((item) => {
    setActiveThread(!item ? null : list.find(i => i.sid === item.sid));
  }, [list]);

  const getThreadInfo = useCallback((thread) => {
    return thread ? infoRef.current[thread.sid] : null;
  }, [infoRef.current]);

  const setActiveThreadIfExists = useCallback((sid) => {
    if (sid) {
      const thread = sid && list?.find(i => i.sid === sid);
      const threadInfo = sid && infoRef.current[sid];
      if (thread && threadInfo) {
        setActiveThread(thread); // Thread found = set it
        delayedActiveThreadSid.current = null; // And clear delayed thread
      } else {
        delayedActiveThreadSid.current = sid; // No thread = delay activation
      }
    }
  }, [list, infoRef.current]);

  useEffect(() => {
    setActiveThreadIfExists(delayedActiveThreadSid.current);
  }, [setActiveThreadIfExists]);

  return {
    loading,
    list,
    activeThread,
    selectThread,
    getThreadInfo,
    setActiveThreadIfExists
  };
};
