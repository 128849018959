import React from "react";
import classnames from "classnames";
import Typography from "../../../Typography";
import { Select } from "../../../form";
import Checkbox from "../../../form/Checkbox";
import { FieldController } from "components/form/FieldController/FieldController";
import Button from "../../../Button";
import { filtersIcon, mapIcon } from "../../../Icon";
import { SORT } from "../../sort";
import { useSearchContext } from "../../useSearchContext";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import FilterAmenities from "./FilterAmenities";
import FilterRentalTypes from "./FilterRentalTypes";
import FilterClass from "./FilterClass";
import FilterPrice from "./FilterPrice";
import FilterLength from "./FilterLength";
import FilterResetButton from "./FilterResetButton";
import classes from "./Header.module.css";

const LIMIT1 = 50;
const ROUND1 = 50;

const LIMIT2 = 1000;
const ROUND2 = 500;

function formatNumberResultsFound(n) {
  if (n > LIMIT2) {
    return `${Math.floor(n / ROUND2) * ROUND2}+`;
  }

  if (n > LIMIT1) {
    return `${Math.floor(n / ROUND1) * ROUND1}+`;
  }

  return `${n}`;
}

export const Header = () => {
  const {
    searchData,
    boundedList,
    mapOpen,
    setMapOpen,
    setMobileFiltersOpen,
    activeSort,
    setActiveSort,
    filtersControl,
    filtersWatch,
    applyFilters,
    hasSetFilters,
    activeFilters,
    resetAllFilters
  } = useSearchContext();

  const isSmallWidth = useMatchMedia({ minWidth: MQ_BP.small, maxWidth: MQ_BP.medium });
  const isBetweenSmallAndLargeWidth = useMatchMedia({ minWidth: MQ_BP.small, maxWidth: MQ_BP.large });

  if (!boundedList || !searchData) {
    return null;
  }
  const { checked } = filtersControl.getValues().instantRentalsOnly;
  const itemsCount = boundedList?.length || 0;
  const result = formatNumberResultsFound(itemsCount);

  return (
    <div
      className={classnames(
        classes.root,
        mapOpen ? classes.isMapOpen : classes.isMapClosed
      )}
    >
      <div className={classes.container}>
        <div className={classes.filtersContainer}>
          <div className={`${classes.filters} ml-neg-4 ml-neg-l-8 mr-neg-8 mb-m-16 mr-xl-0 mb-xl-20`}>
            <FilterClass classesList={searchData.classes} />
            <FilterLength min={searchData.length_min} max={searchData.length_max} />
            <FilterPrice min={searchData.price_min} max={searchData.price_max} />
            <FilterRentalTypes mapOpen={mapOpen} filtersControl={filtersControl} filtersWatch={filtersWatch} />
            <FilterAmenities amenities={searchData.amenities} />
            <FilterResetButton
              resetAllFilters={resetAllFilters}
              hasSetFilters={hasSetFilters}
              activeFilters={activeFilters}
              mapOpen={mapOpen}
            />
          </div>

          {(!mapOpen || (mapOpen && !isBetweenSmallAndLargeWidth)) && (
            <div className={`${classes.instantRentalsOnly} b-xl-16`}>
              <FieldController
                name="instantRentalsOnly"
                control={filtersControl}
                render={(renderProps) => (
                  <Checkbox
                    {...renderProps}
                    pseudoCheckClassName={classes.instantRentalsOnlyCheck}
                    labelTextClassName={classes.instantRentalsOnlyCheckboxTxt}
                    id={'instantRentalsOnly'}
                    name={'instantRentalsOnly'}
                    text={isSmallWidth && !mapOpen ? "Instant rentals " : "Instant rentals only"}
                    onChange={(event) => {
                      renderProps.onChange({
                        isMobileChecked: event.target.checked,
                        checked: event.target.checked
                      });
                      applyFilters();
                    }}
                    checked={checked}
                  />
                )}
              />
            </div>
          )}
        </div>
        <div className={`${classes.mobileRow}`}>
          {itemsCount > 0 && (
            <div className={`${classes.resultCard} mt-m-12 mt-xl-4`}>
              <Typography
                className={classes.resultsFound}
                variant="body"
                size="l"
              >
                {`${result} result${itemsCount > 1 ? "s" : ""} found`}
              </Typography>
            </div>
          )}

          <div className={`${classes.showMap} t-4 t-xl-0`}>
            <Button
              ghost
              icon={mapIcon}
              onClick={() => setMapOpen(true)}
            >
              {"Show Map"}
            </Button>
          </div>
        </div>

        <div className={`${classes.mobileRow} mt-16 mb-16`}>
          <div className={classes.filtersButton}>
            <Button
              secondary
              icon={filtersIcon}
              onClick={() => setMobileFiltersOpen(true)}
            />
          </div>

          <div className={`${classes.sort} mt-m-4 mt-xl-0`}>
            <Select
              className={`${classes.sortSelect} fw`}
              id="sort"
              label="Sort"
              options={SORT.options}
              value={activeSort}
              onChange={setActiveSort}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
