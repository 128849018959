import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Select } from "components/form";
import classes from "./ReviewsSelect.module.css";

export const ReviewsSelect = ({ vehicleOptions = [] }) => {
  const [selectRole, setSelectRole] = useState("1");
  const showReviewsOptions = [
    {
      text: 'As an owner',
      value: '1'
    },
    {
      text: 'As an renter',
      value: '2'
    }
  ];
  const onChangeRole = useCallback((role) => {
    if (selectRole !== role) setSelectRole(role);
  }, [selectRole]);

  const onChangeVehicle = useCallback(() => {
  }, []);

  return (
    <div className={classes.container}>
      <div className="row">
        <div className="
            col-12 col-m-6 col-l-4 col-xl-4
            t-16 b-16 t-m-20 b-m-20
        ">
          <Select
            className="fw"
            id="show_reviews"
            label="Show Reviews"
            options={showReviewsOptions}
            value={selectRole}
            onChange={onChangeRole}
          />
        </div>
        {selectRole === "1" && (
          <div className="
            col-12 col-m-6 col-l-8 col-xl-4
            t-16 b-16 t-m-20 b-m-20
          ">
            <Select
              className="fw"
              id="sort"
              label="Vehicle"
              options={vehicleOptions}
              value="1"
              onChange={onChangeVehicle}
            />
          </div>
        )}
      </div>
    </div>
  );
};

// ReviewsSelect.propTypes = {
//   header: PropTypes.string.isRequired,
//   isMobileNavMode: PropTypes.bool.isRequired,
//   setIsMobileNavMode: PropTypes.func.isRequired
// };

// ReviewsSelect.defaultProps = {
//   header: "My Account",
//   isMobileNavMode: true
// };
