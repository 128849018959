import React from "react";

import PropTypes from "prop-types";
import Typography from "../../../../components/Typography";
import style from "./style.module.css";
import { icon } from "./icon";
import Button from "../../../../components/Button";

export const Header = ({ header, isMobileNavMode, setIsMobileNavMode }) => (
  <header className="container header-main">
    <div className={`${style.header} row`}>
      <div className="col-12 col-m-8 col-xl-6 fsc">
        {!isMobileNavMode && (
          <>
            <Button base className={style.header__link} onClick={() => setIsMobileNavMode(true)}>
              {icon}
              <span>My Account</span>
            </Button>
          </>
        )}
        <Typography
          component="h1"
          variant="headline"
          size="l"
          className="title-main"
        >
          {header}
        </Typography>
      </div>
    </div>
  </header>
);

Header.propTypes = {
  header: PropTypes.string.isRequired,
  isMobileNavMode: PropTypes.bool.isRequired,
  setIsMobileNavMode: PropTypes.func.isRequired
};

Header.defaultProps = {
  header: "My Account",
  isMobileNavMode: true
};
