import { gql } from "@apollo/client";
import { baseFragments } from "queries/fragments";

export const userQuery = gql`
  ${baseFragments.bankAccount}
  query {
    user {
      id
      good_sam_membership_validation {
        key
        value
      }
      bank_account {
        ...BankAccount
      }
    }
  }
`;
