import { useEffect, useState, useCallback } from "react";
import { useApolloClient } from "@apollo/client";
import { chatsQuery } from "../queries/chatOpponents";

export const useChatOpponents = () => {
  const apolloClient = useApolloClient();

  const [chatOpponents, setChatOpponents] = useState(null);

  const loadChats = useCallback(async () => {
    const { data: { chats } } = await apolloClient.query({
      query: chatsQuery
    });
    setChatOpponents(chats?.map(opponent => opponent.opponent) || []);
  }, [apolloClient]);

  useEffect(() => {
    loadChats();
  }, [loadChats]);

  return { chatOpponents };
};
