import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import size from "lodash/size";
import { max as rMax, min as rMin } from "ramda";
import { GoogleMap, InfoWindow, MarkerClusterer } from "@react-google-maps/api";
import { MQ_BP, useMatchMedia } from "../../../MatchMedia";
import { useSearchContext } from "../../useSearchContext";
import VehicleCardBridge from "../VehicleCardBridge/VehicleCardBridge";
import { EMPTY_IMAGE, getMapMarkerIcon, USA_CENTER } from "../../../../utils/map";
import MapControls from "./MapControls";
import classes from "./Map.module.css";
import CustomMarker from "./CustomMarker";

const DEFAULT_ZOOM = 10;

// Sizes from design minus border (outset shadow)
const markerClustererStyles = [
  { width: 38, height: 38, url: EMPTY_IMAGE },
  { width: 46, height: 46, url: EMPTY_IMAGE },
  { width: 60, height: 60, url: EMPTY_IMAGE },
  { width: 78, height: 77, url: EMPTY_IMAGE },
  { width: 90, height: 90, url: EMPTY_IMAGE }
];

const Map = (props) => {
  const { className, withControls } = props;
  const mapRef = useRef();
  const [zoom, setZoom] = useState();
  const isSmallDevice = useMatchMedia({ maxWidth: MQ_BP.small });
  const isXSmallDevice = useMatchMedia({ maxWidth: MQ_BP.xSmall });
  const [, update] = useReducer(p => p + 1, 1);

  const {
    searchData,
    rvsInLocation,
    setMapBounds,
    list,
    mapOpen,
    isInFavorites,
    onChangeFavorite,
    showFullList,
    checkboxWasChecked,
    allowInitialLocation,
    setAllowInitialLocation
  } = useSearchContext();

  const [searchList, setSearchList] = useState(list);

  const updateMapPins = useCallback((items) => {
    if (mapRef.current) {

      const bounds = new window.google.maps.LatLngBounds();
      let positions = items.map((item) => {
        return {
          position: {
            lat: item.lt,
            lng: item.ln
          }
        };
      });
      if (searchData?.map) {
        const {
          viewport_northeast_lat: lat1,
          viewport_northeast_lng: lng1,
          viewport_southwest_lat: lat2,
          viewport_southwest_lng: lng2
        } = searchData?.map;

        positions = [
          ...positions,
          {
            position: {
              lat: lat1,
              lng: lng1
            }
          },
          {
            position: {
              lat: lat2,
              lng: lng2
            }
          }
        ];
      }

      positions.forEach(({ position }) => bounds.extend(position));
      mapRef.current.fitBounds(bounds);
    }
  }, [searchData?.map]);

  useEffect(() => {
    if (mapOpen) {
      if (showFullList) {
        setSearchList(list);
      } else {
        const shortList = list.filter(item => rvsInLocation.includes(item.id));
        setSearchList(shortList);
        if (allowInitialLocation) {
          updateMapPins(shortList);
          setAllowInitialLocation(false);
        }
      }
      setMapBounds(mapRef.current.getBounds());
    }
  }, [
    allowInitialLocation,
    setAllowInitialLocation,
    checkboxWasChecked,
    list,
    mapOpen,
    rvsInLocation,
    setMapBounds,
    showFullList,
    updateMapPins
  ]);

  const activePointElement = useRef(null);
  const [popupPosition, setPopupPosition] = useState(null);
  const [popupItems, setPopupItems] = useState([]);
  const [popupOffset, setPopupOffset] = useState(null);
  const [popupActiveItemIndex, setPopupActiveItemIndex] = useState(0);

  const popupItem = popupItems?.[popupActiveItemIndex];
  const popupItemsCount = size(popupItems);

  const updateMapBounds = useCallback(() => {
    if (mapRef.current) {
      setMapBounds(mapRef.current.getBounds());
    }
  }, [setMapBounds]);

  const mapData = searchData?.map;
  const options = useMemo(() => ({
    center: {
      lat: mapData?.center_lat || USA_CENTER.lat,
      lng: mapData?.center_lng || USA_CENTER.lng
    },
    zoom: DEFAULT_ZOOM,
    disableDefaultUI: true
  }), [mapData?.center_lat, mapData?.center_lng]);

  const popupOptions = useMemo(() => ({
    boxClass: classes.popup,
    pixelOffset: new window.google.maps.Size(0, popupOffset)
  }), [popupOffset]);

  const setActiveCluster = useCallback((isActive) => {
    const cl = activePointElement.current?.classList;
    if (cl) {
      if (isActive) {
        cl.add(classes.activePoint);
      } else {
        cl.remove(classes.activePoint);
      }
    }
  }, []);

  const changeActiveCluster = useCallback((element) => {
    setActiveCluster(false);
    activePointElement.current = element;
    setActiveCluster(true);
  }, [setActiveCluster]);

  const clearPopup = useCallback(() => {
    updateMapBounds();

    setPopupPosition(p => {
      if (p) {
        setPopupActiveItemIndex(0);
        setPopupItems([]);
        setActiveCluster(false);
      }
      return null;
    });
  }, [updateMapBounds, setActiveCluster]);

  const panMapForPopup = useCallback((position) => {
    if (isSmallDevice) {
      mapRef.current.panTo(position);
      mapRef.current.panBy(0, 100);
    }
  }, [isSmallDevice]);

  const onClickClusterer = useCallback((c) => {
    setPopupOffset(-32);
    setPopupActiveItemIndex(0);
    setPopupItems(c.markers.map(i => i.vehicleItem));
    setPopupPosition(c.center);
    changeActiveCluster(c.clusterIcon?.div);
    panMapForPopup(c.center);
  }, [changeActiveCluster, panMapForPopup]);

  const onClickNextItem = useCallback(() => {
    setPopupActiveItemIndex(p => rMin(popupItemsCount - 1, p + 1));
  }, [popupItemsCount]);

  const onClickPrevItem = useCallback(() => {
    setPopupActiveItemIndex(p => rMax(0, p - 1));
  }, []);

  const onClickMarker = useCallback((position, item, offset, marker) => {
    setPopupOffset(offset);
    setPopupActiveItemIndex(0);
    setPopupItems([item]);
    setPopupPosition(position);
    changeActiveCluster(marker);
    panMapForPopup(position);
  }, [changeActiveCluster, panMapForPopup]);

  const onMapLoad = useCallback(map => {
    mapRef.current = map;
  }, []);

  const onZoomChanged = useCallback(() => {
    if (mapRef.current) {
      clearPopup();
      setZoom(mapRef.current.zoom);
    }
  }, [clearPopup]);

  const onClickMap = useCallback((event) => {
    if (event?.placeId) {
      // Place's popup will be opened: need to close our popup
      // There is no other way to detect our popup closing
      clearPopup();
    }
  }, [clearPopup]);

  const renderPopupContent = () => {
    return (
      <VehicleCardBridge
        photoUrlPrefix={searchData.photo_url_prefix}
        linkPrefix={searchData.rv_url_prefix}
        item={popupItem}
        variant="map"
        size={isXSmallDevice ? "s" : "m"}
        pageIndex={popupActiveItemIndex + 1}
        totalPages={popupItemsCount}
        onClose={clearPopup}
        onClickNext={onClickNextItem}
        onClickPrevious={onClickPrevItem}
        isInFavorites={isInFavorites}
        onChangeFavorite={onChangeFavorite}
      />
    );
  };

  const onPopupDomReady = useCallback(() => {
    const popup = document.querySelector(".gm-style-iw-a");
    popup && popup.classList.add(classes.rvPopup);
  }, []);

  const showPopup = popupPosition && popupItems?.length > 0;

  const icon = useMemo(() => getMapMarkerIcon(EMPTY_IMAGE, 38), []);

  return (
    <div className={classnames(className, classes.root)}>
      {isSmallDevice && showPopup && (
        <div className={classes.mobilePopup}>
          {renderPopupContent()}
        </div>
      )}
      <GoogleMap
        mapContainerClassName={classes.map}
        options={options}
        onZoomChanged={onZoomChanged}
        onDragEnd={updateMapBounds}
        onClick={onClickMap}
        onLoad={onMapLoad}
      >
        {withControls && (
          <MapControls />
        )}
        {!isSmallDevice && showPopup && (
          <InfoWindow
            key={`${popupPosition.lat}_${popupPosition.lng}`}
            position={popupPosition}
            options={popupOptions}
            onDomReady={onPopupDomReady}
          >
            {renderPopupContent()}
          </InfoWindow>
        )}
        <MarkerClusterer
          key={searchList.length}
          zoomOnClick={false}
          gridSize={100}
          styles={markerClustererStyles}
          onClick={onClickClusterer}
          // Need to re-render markers:
          onClusteringEnd={update}
        >
          {(clusterer) => searchList.map((item) => {
            if (!Number.isFinite(item.lt) || !Number.isFinite(item.ln)) {
              return null;
            }

            return (
              <CustomMarker
                key={item.id}
                mapRef={mapRef}
                item={item}
                clusterer={clusterer}
                icon={icon}
                onClick={onClickMarker}
                zoom={zoom}
              />
            );
          })}
        </MarkerClusterer>
      </GoogleMap>
    </div>
  );
};

Map.propTypes = {
  className: PropTypes.string,
  withControls: PropTypes.bool
};

export default Map;
