import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import GoodsamMembershipModal from "./GoodsamMembershipModal";
import { ValidateMembership } from "../ValidateMembership/ValidateMembership";
import { ModalMembershipValidation } from "components/ModalMembershipValidation/ModalMembershipValidation";
import { useMembershipOffers } from "components/GoodsamMembership/query";

const contentData = {
  membershipTitle:
    "Join now to receive up to a $30 merchandise certificate*",
  membershipValidateText: "Already a Member?",
  membershipValidateCtaLabel: "Validate Membership",
  membershipDisclaimer: `
    <p>
      *Offers are not retroactive.
      Sales tax on memberships is applicable in certain states.
    </p>
    <p>
      Good Sam membership benefits, promotions and/or special
      offers are subject to change without prior notice. Some
      benefits, products and services offered by Good Sam may not
      be available to residents in certain states or outside the
      U.S. Some restrictions apply to certain benefits. Membership
      fees are non-refundable.
    </p>
  `,
  failedValidationMessageTitle: "Membership not validated",
  failedValidationMessageText:
    "The information you provided is not associated with an active Good Sam Membership. Please review and submit again, or contact us.",
  tryAgainButton: {
    label: "Try Again"
  },
  contactButton: {
    link: "https://www.goodsam.com/contact/",
    label: "Contact Good Sam"
  },
  cancelButton: {
    label: "Cancel"
  },
  confirmButton: {
    label: "Confirm"
  }
};

const GoodsamMembership = (props) => {
  const {
    activeId,
    show,
    clubId = "goodsam",
    onClose,
    setIsGoodSamMember,
    setIsDeclined,
    backTo,
    onSavePlan,
    setIsShowMembershipModal,
    onMembershipValidationSuccess,
    ...restProps
  } = props;
  const [inVerifying, setInVerifying] = useState(false);

  const toggleVerify = useCallback(() => {
    onClose();
    setInVerifying((p) => !p);
  }, [onClose]);

  const handleClose = useCallback(() => {
    toggleVerify();
    if (!show && setIsShowMembershipModal) {
      setIsShowMembershipModal(true);
    }
  }, [setIsShowMembershipModal, show, toggleVerify]);

  const { loading, data } = useMembershipOffers();

  if (loading) return null;

  return (
    <>
      <GoodsamMembershipModal
        activeId={activeId}
        content={contentData}
        loading={loading}
        options={data?.good_sam_membership_offers || []}
        show={show}
        onClose={onClose}
        backTo={backTo}
        toggleVerify={toggleVerify}
        onSavePlan={onSavePlan}
        {...restProps}
      />

      {clubId && (
        <ModalMembershipValidation
          clubId={clubId}
          show={inVerifying}
          onClose={handleClose}
          onMembershipValidationSuccess={onMembershipValidationSuccess}
        />
      )}
    </>
  );
};

GoodsamMembership.propTypes = {
  activeId: PropTypes.string,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setIsGoodSamMember: PropTypes.func.isRequired,
  setIsDeclined: PropTypes.func.isRequired,

  /**
   * Will replace modal cross with button to go back on other modal
   */
  // backTo={{ label: "Back to Booking", onClick: () => null }}
  backTo: PropTypes.exact({
    onClick: PropTypes.func,
    label: PropTypes.string.isRequired
  }),
  onSavePlan: PropTypes.func.isRequired
};

export default GoodsamMembership;
