import { gql } from "@apollo/client";

export const rvInitialData = gql`
  query rvInitialData($id: Int!, $for_listing: Boolean!) {
    rv(id: $id, for_listing: $for_listing) {
      location {
        state
        city
        latitude
        longitude
        full_address
        short_address
      }
      id
      year
      make
      manufacturer
      model
      weight
      slide_outs
      length {
        feet_as_part
        inches_total
      }
      sleeps
      class
      owner {
        id
        good_sam_membership_validation {
          key
          value
        }
      }
      paid_delivery
      miles_included
      generator_hours_included
      limited_daily_mileage
      limited_generator_hours
      is_trailed_class
      generator_not_applicable
      mileage_overage_rate {
        user_friendly
      }
      generator_overage_rate {
        user_friendly
      }
      availability_calendar {
        date
        busy
        available
        price {
          dollars_as_part
          cents_total
          user_friendly
          dollars_as_part
        }
      }
      delivery_option
      dor_delivery_option
      daily_rate {
        cents_total
        dollars_as_part
        cents_as_part
        user_friendly
      }
      minimum_duration
      instant_rentals
      instant_rentals__days_before_the_rental
      allow_standard_rental
      allow_delivery_only_rental
      dor_paid_delivery
      add_ons {
        name
        price {
          cents_total
        }
        price_type {
          key
          value
        }
      }
      specific_delivery_locations {
        id
        delivery_fee {
          user_friendly
        }
        description
        location {
            full_address
            latitude
            longitude
        }
      }
      dor_specific_delivery_locations {
        id
        delivery_fee {
          user_friendly
        }
        description
        location {
            full_address
            building
            street
            city
            state
            zip
            zip_suffix
            latitude
            longitude
        }
      }
    }
  }
`;
