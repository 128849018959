import React, { useCallback, useState } from "react";
import Button from "components/Button";
import GoodsamMembership from "components/GoodsamMembership";
import { ReactComponent as IconLogo } from "./goodsam-logo.svg";
import Typography from "components/Typography";
import FormattedPrice from "components/FormattedPrice/FormattedPrice";
import classes from "./BecomeGoodSamMember.module.css";

const BecomeGoodSamMember = ({
  price,
  membershipId,
  setMembershipId
}) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setOpen((p) => !p);
  }, []);

  const onSavePlan = useCallback(
    (id) => {
      setMembershipId(id);
      toggleOpen();
    },
    [setMembershipId, toggleOpen]
  );
  let content = (
    <span className={classes.content}>
      Become
      <span className={`${classes.logo} ml-4 mr-4 mb-8 mb-m-0`}>
        <IconLogo />
      </span>
      <span className={`mb-12 ${classes.member}`}>
        <span className="mr-4">Member</span>
        <span className={`${classes.price}`}>
        and
          <strong className="ml-4">
          save <span className={classes.formattedPrice}><FormattedPrice value={price} className="ml-0 ml-l-0" isBold /></span>
          </strong>
        </span>
      </span>
    </span>
  );

  if (!price.dollars_as_part) {
    content = (<span>Become a <span className={classes.logo}><IconLogo /></span> Member</span>);
  }
  return (
    <div className={`${classes.container} mt-12 mt-m-20 mb-16 mb-m-20`}>
      <div className={`${classes.contentContainer} fbc t-16 r-32 b-16 l-32 t-m-20 b-m-20`}>
        <div className={`${classes.leftColumn} mr-m-24`}>
          <Typography variant="body" size="l">
            {content}
          </Typography>
        </div>
        <div className={`${classes.rightColumn} b-16 b-m-0`}>
          <Button secondary onClick={toggleOpen}>
            Choose subscription plan
          </Button>
        </div>
      </div>

      <GoodsamMembership
        activeId={membershipId}
        show={open}
        onClose={toggleOpen}
        onSavePlan={onSavePlan}
        backTo={{ label: "Back to Booking" }}
      />
    </div>
  );
};

export default BecomeGoodSamMember;
