import React, { useCallback } from "react";
import { useBookingModalContext } from "processes/Booking/components/BookingModal/BookingModalContext";
import BookingInsurance from "processes/Booking/BookingInsurance/BookingInsurance";

const InsurancePlan = ({ showBookingInsuranceModal, setShowBookingInsuranceModal }) => {
  const {
    rvData,
    rentalType,
    insuranceId,
    setInsuranceId,
    setIsModalHidden
  } = useBookingModalContext();

  const toggleOpen = useCallback(() => {
    setShowBookingInsuranceModal((p) => !p);
    setIsModalHidden((p) => !p);
  }, [setIsModalHidden, setShowBookingInsuranceModal]);

  const onSave = useCallback(
    (value) => {
      setInsuranceId(value);
      toggleOpen();
    },
    [setInsuranceId, toggleOpen]
  );

  return (
    <>
      <BookingInsurance
        rvId={rvData.id}
        rentalType={rentalType}
        show={showBookingInsuranceModal}
        onClose={toggleOpen}
        setIsShowBookingInsurance={toggleOpen}
        onSelectInsurance={onSave}
        insurance={insuranceId}
        backTo={{ label: "Back to Booking" }}
      />
    </>
  );
};

export default InsurancePlan;
