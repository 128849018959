import React, { useRef, useState, useCallback, useEffect, useMemo } from "react";
import classnames from "classnames";
import Modal from "components/Modal/Modal";
import Typography from "components/Typography";
import { SpinnerBack } from "components/Spinner/Spinner";
import NotificationPopover from "components/NotificationPopover/NotificationPopover";
import { additionalInsuranceList } from "queries/additionalInsuranceList";
import { useQuery } from "@apollo/client";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import { useScrollBarWidth } from "hooks/useScrollBarWidth";
import { NBSP_CODE } from "utils/basic";
import { useScrollShadow } from "processes/Booking/components/hooks/useScrollShadow";
import { useCalculatePrice } from "processes/Booking/CalculatePrice/useCalculatePrice";
import { CALCULATE_PRICE_ERROR_CODES } from "constants/errorCodes";
import { useRentalPaymentModalSteps } from './utils/steps';
import AsideRvInfo from "./components/AsideRvInfo/AsideRvInfo";
import BookingModalMenu from "./components/BookingModalMenu/BookingModalMenu";
import { STEPS } from "components/RentalPaymentModal/constants/steps";
import { RESERVE_ERRORS } from "constants/errorCodes";
import { ADD_INSURANCE_LIST_MODES } from "components/AdditionalInsuranceList/constants";
import {
  getTotalPackagesIds,
  isNextStep,
  mergeAdditionalInsuranceData,
  getInitialAdditionalInsuranceData,
  addAdditionalInsurance,
  removeExtraPackagesId,
  setInitialDefaultTags
} from "components/AdditionalInsuranceList/utils";
import { getErrorsAndSuccess } from "utils/extractErrors";
import style from "./RentalPaymentModal.module.css";

const addInsuranceListEAS = getErrorsAndSuccess("additional_insurance_list");

const RentalPaymentModal = ({
  rental,
  loading,
  onClose,
  refetch,
  onStartDriverVerify
}) => {
  const ref = useRef(null);

  const {
    rv: rvData
  } = rental;

  const [error, setError] = useState("");
  const [isNoSplitPayments, setIsNoSplitPayments] = useState(false);
  const [coupon, setCoupon] = useState(null);
  const [membershipId, setMembershipId] = useState(null);
  const [insuranceId, setInsuranceId] = useState(rental?.price?.insurance_package_id);
  const [isInterruptionVisible, setIsInterruptionVisible] = useState(true);
  const [isRefetchCalculatePrice, setIsRefetchCalculatePrice] = useState(true);
  const [isLoadingCalculatePriceRefetch, setIsLoadingCalculatePriceRefetch] = useState(false);
  const [addInsuranceList, setAddInsuranceList] = useState({});
  const [selectedPackagesAdditionalInsurance, setSelectedPackagesAdditionalInsurance] = useState({
    insurancesIds: [],
    packagesIds: []
  });
  const [isRefetchRentalPaymentModalDataLoading, setIsRefetchRentalPaymentModalDataLoading] = useState(false);
  const [isTII, setIsTII] = useState(false);
  const [tiiDefaultTags, setTiiDefaultTags] = useState(setInitialDefaultTags());
  const [isClearTii, setIsClearTii] = useState(false);
  const hasAddInsuranceListDataChanged = useRef(false);

  const addInsuranceListVariables = useMemo(() => {
    return {
      rv: rvData?.id,
      departure_date: rental.departure_date,
      return_date: rental.return_date,
      rental_type: Number(rental.rental_type.key)
    };
  }, [rental.departure_date, rental.rental_type.key, rental.return_date, rvData?.id]);

  const { loading: additionalInsuranceListLoading, data: additionalInsuranceListData, refetch: refetchAdditionalInsuranceList } = useQuery(additionalInsuranceList, {
    variables: addInsuranceListVariables,
    fetchPolicy: "no-cache"
  });

  const priceParams = useMemo(() => ({
    rv: rental.rv?.id,
    current_rental: rental.id,
    rental_type: Number(rental.rental_type.key),
    departure_date: rental.departure_date,
    return_date: rental.return_date,
    add_ons: rental.last_suggestion?.price?.add_ons?.map(add_on => add_on.id),
    delivery_location: rental.pick_up_location === rental.rv?.location?.short_address ? null : rental.pick_up_location,
    specific_delivery_location_id: rental.specific_delivery_location_id,
    rental_fee: rental.price?.rental_fee_calculated?.cents_total !== rental.price?.rental_fee?.cents_total ? rental.price?.rental_fee?.dollars_as_part : null,
    delivery_fee: rental.price?.delivery_fee_calculated?.cents_total !== rental.price?.delivery_fee?.cents_total ? rental.price?.delivery_fee?.dollars_as_part : null,
    is_predefined: true,
    no_split_payments: isNoSplitPayments,
    additional_insurances: removeExtraPackagesId(getTotalPackagesIds(selectedPackagesAdditionalInsurance, addInsuranceList || {}))
  }), [addInsuranceList, isNoSplitPayments, rental.departure_date, rental.id, rental.last_suggestion?.price?.add_ons, rental.pick_up_location, rental.price?.delivery_fee?.cents_total, rental.price?.delivery_fee?.dollars_as_part, rental.price?.delivery_fee_calculated?.cents_total, rental.price?.rental_fee?.cents_total, rental.price?.rental_fee?.dollars_as_part, rental.price?.rental_fee_calculated?.cents_total, rental.rental_type.key, rental.return_date, rental.rv?.id, rental.rv?.location?.short_address, rental.specific_delivery_location_id, selectedPackagesAdditionalInsurance]);

  const [
    {
      loading: calculatePriceLoading,
      data: price,
      error: calculatePriceError,
      price_form_detailed,
      errorCode: calculatePriceErrorCode,
      errorMessage: calculatePriceErrorMessage
    },
    onCalculatePrice
  ] = useCalculatePrice(priceParams.rv, priceParams.current_rental);

  const {
    steps,
    currentStepIndex,
    onClickStep,
    onClickStepById,
    onNextStep,
    onPrevStep
  } = useRentalPaymentModalSteps(rvData, addInsuranceList, isTII);

  useEffect(() => {
    if (steps[currentStepIndex]?.id === STEPS.PAYMENT_OPTIONS.ID) {
      onCalculatePrice({
        ...priceParams,
        coupon_code: coupon?.code,
        good_sam_membership_package: membershipId,
        insurance_package: insuranceId,
        is_trip_interruption_insurance: isInterruptionVisible,
        no_split_payments: isNoSplitPayments
      });
    }
  }, [priceParams, coupon, onCalculatePrice, membershipId, insuranceId, isInterruptionVisible, steps, currentStepIndex, isNoSplitPayments]);

  useEffect(() => {
    if (additionalInsuranceListData?.additional_insurance_list?.insurances && !hasAddInsuranceListDataChanged.current) {

      onCalculatePrice({
        ...priceParams,
        additional_insurances: getTotalPackagesIds(getInitialAdditionalInsuranceData(additionalInsuranceListData?.additional_insurance_list), additionalInsuranceListData?.additional_insurance_list)
      });

      if (price?.trip_interruption_insurance) {
        hasAddInsuranceListDataChanged.current = true;
        setSelectedPackagesAdditionalInsurance(getInitialAdditionalInsuranceData(addAdditionalInsurance(additionalInsuranceListData?.additional_insurance_list, price?.trip_interruption_insurance?.cents_total), ADD_INSURANCE_LIST_MODES.USING));
        setAddInsuranceList(addAdditionalInsurance(additionalInsuranceListData?.additional_insurance_list, price.trip_interruption_insurance?.cents_total));
        setIsInterruptionVisible(false);
      }
    }
  }, [additionalInsuranceListData?.additional_insurance_list, onCalculatePrice, price, priceParams]);

  const [completedSteps, setCompletedSteps] = useState([]);

  const [showAsideRvInfoDetails, setShowAsideRvInfoDetails] = useState(false);
  const isPaymentMethodStep = steps[currentStepIndex]?.id === STEPS.PAYMENT_METHOD.ID;
  const isMaxMedium = useMatchMedia({ maxWidth: MQ_BP.medium });
  const isMinHeightMedium = useMatchMedia({ mediaQuery: "(min-width: 1024px) and (min-height: 730px)" });
  const mdlRef = useScrollBarWidth();
  const stepContainerRef = useRef(null);
  const scrollShadowData = useScrollShadow(stepContainerRef, loading);

  const onRefetchAdditionalInsuranceList = useCallback(
    async () => {
      setIsRefetchRentalPaymentModalDataLoading(true);
      const responseAddInsuranceList = await refetchAdditionalInsuranceList(addInsuranceListVariables);

      if (addInsuranceListEAS.isQuerySuccess(responseAddInsuranceList)) {
        setSelectedPackagesAdditionalInsurance(mergeAdditionalInsuranceData(selectedPackagesAdditionalInsurance, addInsuranceListEAS.getQueryData(responseAddInsuranceList)));
        setAddInsuranceList(addInsuranceListEAS.getQueryData(responseAddInsuranceList));
      }

      setIsRefetchRentalPaymentModalDataLoading(false);
    },
    [addInsuranceListVariables, refetchAdditionalInsuranceList, selectedPackagesAdditionalInsurance]
  );

  const handleCalculatePriceRefetch = useCallback(async (isCondition) => {
    if (
      isCondition
      && isRefetchCalculatePrice
    ) {
      onCalculatePrice({
        ...priceParams,
        coupon_code: coupon?.code,
        good_sam_membership_package: membershipId,
        insurance_package: insuranceId,
        is_trip_interruption_insurance: isInterruptionVisible,
        no_split_payments: isNoSplitPayments
      });
      setIsRefetchCalculatePrice(false);
    }
  }, [coupon?.code, insuranceId, isInterruptionVisible, isNoSplitPayments, isRefetchCalculatePrice, membershipId, onCalculatePrice, priceParams]);

  const handleNextStep = useCallback(() => {
    onNextStep();
    handleCalculatePriceRefetch(steps[currentStepIndex]?.id === STEPS.PAYMENT_METHOD.ID);
  }, [currentStepIndex, handleCalculatePriceRefetch, onNextStep, steps]);

  const handleOnClickStep = useCallback(
    (data) => {
      if (steps[currentStepIndex]?.id === STEPS.ADDITIONAL_INSURANCE.ID) {
        if (isNextStep(selectedPackagesAdditionalInsurance, addInsuranceList)) {
          handleCalculatePriceRefetch(data?.isLastStep);
          onClickStep(data);
        } else {
          setError("Error Insurance");
        }
      } else {
        handleCalculatePriceRefetch(data?.isLastStep);
        onClickStep(data);
      }
    },
    [addInsuranceList, currentStepIndex, handleCalculatePriceRefetch, onClickStep, selectedPackagesAdditionalInsurance, steps]
  );

  useEffect(() => {
    switch (calculatePriceErrorCode) {
    case CALCULATE_PRICE_ERROR_CODES.MINIMUM_RENTAL_DURATION:
      setError(calculatePriceErrorMessage);
      break;
    case RESERVE_ERRORS.AVAILABILITY_DELIVERY:
      setError(calculatePriceErrorMessage);
      break;
    case RESERVE_ERRORS.BOOKING_RENTAL_FEE_LIMIT:
      setError(calculatePriceErrorMessage);
      break;
    case RESERVE_ERRORS.BOOKING_TII_NOT_APPLICABLE:
      setError(calculatePriceErrorMessage);
      break;
    case RESERVE_ERRORS.AVAILABILITY_ADDITIONAL_INSURANCE:
      setError(calculatePriceErrorMessage);
      break;
    default:
      break;
    }
  }, [calculatePriceErrorCode, calculatePriceErrorMessage]);

  useEffect(() => {
    if (price?.trip_interruption_insurance) {
      setIsTII(Boolean(price?.trip_interruption_insurance?.cents_total));
    }
  }, [price]);

  return (
    <Modal
      showCross
      show={true}
      onClose={onClose}
      modalClassnames={classnames(style.modal)}
      modalWrapClassnames={classnames(style.modalRoot)}
      modalCrossButtonClassname={style.crossButton}
      {...(isMaxMedium || !isMinHeightMedium ? { variant: "full-screen" } : null)}
      mdlRef={mdlRef}
    >
      <div ref={ref} className={style.modalContent}>
        {!loading && !calculatePriceLoading && rvData && (
          <main className="">
            <div className={classnames("row t-16 t-m-0", style.wrap)}>
              <div
                className={classnames(
                  style.mobileHeader,
                  "t-16 t-m-20 b-m-20 p-12 fw"
                )}
              >
                <Typography
                  variant="subtitle"
                  size="m"
                  weight="normal"
                  className="dn-l db"
                >
                  {`Request to book${NBSP_CODE}`}
                </Typography>
                <Typography
                  variant="subtitle"
                  size="m"
                  weight="bold"
                  className="dn-l db"
                >
                  {rvData.rv_name}
                </Typography>
              </div>
              <AsideRvInfo
                rental={rental}
                showAsideRvInfoDetails={showAsideRvInfoDetails}
                setShowAsideRvInfoDetails={setShowAsideRvInfoDetails}
              />
              <section
                className={classnames(
                  style.content,
                  "df fdc mr-0 ml-0 mr-l-32 ml-l-32"
                )}
              >
                <BookingModalMenu
                  steps={steps}
                  currentStep={currentStepIndex}
                  completedSteps={completedSteps}
                  onClickItem={onClickStep}
                  hasScrollShadow={scrollShadowData.top}
                />
                <div ref={stepContainerRef} className={classnames(style.stepsContainer, isPaymentMethodStep && style.isPaymentMethodStep, "df fdc fw ")}>
                  {steps.map((i, index) => {
                    const isActive = currentStepIndex === index;
                    return (
                      <div
                        key={i.id}
                        className={classnames(
                          isActive ? style.stepActive : style.stepInactive
                        )}
                      >
                        <i.component
                          isVisible={isActive}
                          modalRef={ref}
                          price={price}
                          price_form_detailed={price_form_detailed}
                          setIsRefetchCalculatePrice={setIsRefetchCalculatePrice}
                          handleOnClickStep={handleOnClickStep}
                          rental={rental}
                          hasScrollShadow={scrollShadowData.bottom}
                          isPaymentMethodStep={isPaymentMethodStep}
                          isInterruptionVisible={isInterruptionVisible}
                          coupon={coupon}
                          membershipId={membershipId}
                          insuranceId={insuranceId}
                          calculatePriceLoading={calculatePriceLoading}
                          calculatePriceError={calculatePriceError}
                          setCoupon={setCoupon}
                          handleNextStep={handleNextStep}
                          setIsInterruptionVisible={setIsInterruptionVisible}
                          setMembershipId={setMembershipId}
                          setInsuranceId={setInsuranceId}
                          isNoSplitPayments={isNoSplitPayments}
                          setIsNoSplitPayments={setIsNoSplitPayments}
                          refetch={refetch}
                          onCloseRentalPaymentModal={onClose}
                          onStartDriverVerify={onStartDriverVerify}
                          rental_type={Number(rental.rental_type.key)}
                          onClickStepById={onClickStepById}
                          addInsuranceList={addInsuranceList}
                          additionalInsuranceListLoading={additionalInsuranceListLoading}
                          isRefetchRentalPaymentModalDataLoading={isRefetchRentalPaymentModalDataLoading}setIsRefetchRentalPaymentModalDataLoading={setIsRefetchRentalPaymentModalDataLoading}
                          setError={setError}
                          additional_insurances={removeExtraPackagesId(getTotalPackagesIds(selectedPackagesAdditionalInsurance, addInsuranceList || {}))}
                          selectedPackagesAdditionalInsurance={selectedPackagesAdditionalInsurance}
                          setSelectedPackagesAdditionalInsurance={setSelectedPackagesAdditionalInsurance}
                          refetchAdditionalInsuranceList={refetchAdditionalInsuranceList}
                          onRefetchAdditionalInsuranceList={onRefetchAdditionalInsuranceList}
                          isClearTii={isClearTii}
                          setIsClearTii={setIsClearTii}
                          tiiDefaultTags={tiiDefaultTags}
                          setTiiDefaultTags={setTiiDefaultTags}
                        />
                      </div>
                    );
                  })}
                </div>
              </section>
            </div>
          </main>
        )}
        {(loading || calculatePriceLoading) && (
          <div className="t-60 b-60 fcc">
            <SpinnerBack />
          </div>
        )}

        {error?.length !== 0 && (
          <NotificationPopover
            show
            status="error"
            text={error}
            onClose={() => {
              setError('');
            }}
            bottomIndent={{
              hasIndent: true,
              size: 'small'
            }}
          />
        )}
      </div>
    </Modal>
  );
};

export default RentalPaymentModal;
